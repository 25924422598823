<template>
  <div
    v-if="slotBase && slot"
    class="v-slots-half"
  >
    <template v-if="appConfig.VueSettingsPreRun.MenuSlotsHalfLayout === 'OstInEdhil'">
      <div
        v-if="isSmall"
        class="v-slots-half-mobile"
      >
        <h1
          class="v-slots-half-small-title"
          v-html="sanitize(slotBase.Name)"
        />
        <div class="v-slots-half-mobile-swipers v-mb-sm v-mx-sm">
          <arora-swiper-slider
            class-name="v-swiper-left"
            centered
            disable-breakpoints
            loop
            :items="
              leftSlotProducts.filter((product) => !menuStore.ProductsInStopList.includes(product.ID))
            "
            :autoplay-delay="0"
            :effect-modifier="3"
            :enable-navigation="false"
            :horizontal="false"
            :max-items="2"
            :show-part-of-next-slide="false"
            :space-between="0"
            effect="coverflow"
            pagination-type="dynamic-bullets"
            v-model:active-index="activeIndexLeft"
          >
            <template #item="item: ProductInList">
              <div
                class="v-slots-half-mobile-swiper v--left"
                :class="{
                  disabled: menuStore.ProductsInStopList.includes(item.ID)
                }"
              >
                <div class="v-item-slot-half--top">
                  <div
                    class="v-item-slot-half--name"
                    v-html="sanitize(item.Name)"
                  />
                  <div
                    v-if="item.Price > 0"
                    class="v-item-slot-half--price"
                  >
                    <common-currency :amount="item.Price" />
                  </div>
                </div>

                <div class="v-item-slot-half--img">
                  <arora-nuxt-image
                    disable-lazy
                    disable-resize
                    :image="
                      imageExists(item.CombineMobileImage) ? item.CombineMobileImage : item.NormalImage
                    "
                    :image-type="
                      imageExists(item.CombineMobileImage) ? 'CombineMobile' : 'ProductNormal'
                    "
                    :alt="item.Name"
                    :height="500"
                    :width="500"
                  />
                </div>
              </div>
            </template>
          </arora-swiper-slider>

          <arora-swiper-slider
            class-name="v-swiper-right"
            centered
            disable-breakpoints
            loop
            :items="
              rightSlotProducts.filter((product) => !menuStore.ProductsInStopList.includes(product.ID))
            "
            :autoplay-delay="0"
            :effect-modifier="3"
            :enable-navigation="false"
            :horizontal="false"
            :max-items="2"
            :show-part-of-next-slide="false"
            :space-between="0"
            effect="coverflow"
            pagination-type="dynamic-bullets"
            v-model:active-index="activeIndexRight"
            @loaded="async () => await randomize()"
          >
            <template #item="item: ProductInList">
              <div
                class="v-slots-half-mobile-swiper v--right"
                :class="{
                  disabled: menuStore.ProductsInStopList.includes(item.ID)
                }"
              >
                <div class="v-item-slot-half--top">
                  <div
                    class="v-item-slot-half--name"
                    v-html="sanitize(item.Name)"
                  />
                  <div
                    v-if="item.Price > 0"
                    class="v-item-slot-half--price"
                  >
                    <common-currency :amount="item.Price" />
                  </div>
                </div>
                <div class="v-item-slot-half--img">
                  <arora-nuxt-image
                    disable-lazy
                    disable-resize
                    :image="
                      imageExists(item.CombineMobileImage) ? item.CombineMobileImage : item.NormalImage
                    "
                    :image-type="
                      imageExists(item.CombineMobileImage) ? 'CombineMobile' : 'ProductNormal'
                    "
                    :alt="item.Name"
                    :height="500"
                    :width="500"
                  />
                </div>
              </div>
            </template>
          </arora-swiper-slider>

          <div class="v-half-fab-wrapper">
            <random-cube-button
              class-name="v-half-fab"
              @click="async () => await randomize()"
            />
          </div>
        </div>
        <div class="v-half-small-cart">
          <div
            v-if="baseProduct"
            class="v-w-100 v-d-flex v-flex-row"
            data-test-id="product-half-option"
          >
            <menu-option-selector
              :options="baseProduct.Options"
              :product="baseProduct"
              :threshold="appConfig.VueSettingsPreRun.ProductOptionThreshold"
              data-test-id="product-page-options"
            />
          </div>
          <arora-button
            :disabled="slotsNotReady"
            :label="translate('addToCartButton.addToCart')"
            @click="async () => await addToBasket()"
          >
            <div class="v-align-currency-center">
              <span v-html="translate('slotsLunchPage.addToCart')" />
              <common-currency :amount="price" />
            </div>
          </arora-button>
        </div>
      </div>
      <div
        v-else
        class="v-slots-half-big"
      >
        <div class="v-cart-half-big-left">
          <h1
            class="v-slots-half-big-title"
            v-html="sanitize(slotBase.Name)"
          />
          <div class="v-cart-half-big-left-scroll-area v-scrollbar v-pm-shadow">
            <common-cards-flex-mesh
              :items="
                appConfig.VueSettingsPreRun.MenuPageHideStopList
                  ? slot.Products.filter((product) => !menuStore.ProductsInStopList.includes(product.ID))
                  : slot.Products
              "
              :max-items="fromPopup ? 3 : 4"
            >
              <template #item="item: ProductInList">
                <div
                  class="v-slot-product-single"
                  :class="{
                    'v-slot-product-single-selected-left':
                      item.ID === slotLeft?.ID && item.ID !== slotRight?.ID,
                    'v-slot-product-single-selected-right':
                      item.ID !== slotLeft?.ID && item.ID === slotRight?.ID,
                    'v-slot-product-single-selected-both':
                      item.ID === slotLeft?.ID && item.ID === slotRight?.ID
                  }"
                  data-test-id="slots-half-add-product"
                  @click="() => selectSlot(item)"
                >
                  <div
                    v-if="item.ID === slotLeft?.ID || item.ID === slotRight?.ID"
                    class="v-slot-half-image v-mb-sm"
                  >
                    <arora-nuxt-image
                      :key="item.ID"
                      disable-lazy
                      :image="
                        imageExists(item.CombineMobileImage) ? item.CombineMobileImage : item.NormalImage
                      "
                      :alt="item.Name"
                    />
                    <arora-nuxt-image
                      :key="item.ID"
                      disable-lazy
                      :image="
                        imageExists(item.CombineMobileImage) ? item.CombineMobileImage : item.NormalImage
                      "
                      :alt="item.Name"
                    />
                  </div>
                  <arora-nuxt-image
                    v-else
                    :key="item.ID"
                    class="v-mb-sm"
                    :image="
                      imageExists(item.CombineMobileImage) ? item.CombineMobileImage : item.NormalImage
                    "
                    :image-type="
                      imageExists(item.CombineMobileImage) ? 'CombineMobile' : 'ProductNormal'
                    "
                    :alt="item.Name"
                  />
                  <div
                    class="v-slot-product-single-title"
                    v-html="sanitize(item.Name)"
                  />
                  <common-currency
                    v-if="item.Price > 0"
                    :amount="item.Price"
                  />
                  <common-quantity-measure
                    v-else-if="item.Quantity > 0"
                    :measure="item.Measure"
                    :quantity="item.Quantity"
                  />
                  <common-quantity-measure
                    v-else-if="item.Weight > 0"
                    :quantity="item.Weight"
                  />
                </div>
              </template>
            </common-cards-flex-mesh>
          </div>
        </div>
        <div class="v-slots-half-big-right">
          <div class="v-slots-half-big-selection-image">
            <slots-half-images
              :slot-left="slotLeft"
              :slot-right="slotRight"
            />
            <random-cube-button
              class-name="v-btn v-btn-border"
              @click="async () => await randomize()"
            />
          </div>
          <div
            v-if="
              !stringIsNullOrWhitespace(slotBase.NoOrderTypeReasonText) ||
              !stringIsNullOrWhitespace(slotBase.PersonalProductMessage)
            "
            class="v-slots-half-big-selection-slot-message"
          >
            <icon-menu-info class="v-mr-xs" />
            <div class="v-d-flex v-flex-column v-justify-content-center">
              <span v-html="sanitize(slotBase.NoOrderTypeReasonText)" />
              <span v-html="sanitize(slotBase.PersonalProductMessage)" />
            </div>
          </div>
          <div class="v-slots-half-big-selection-slot-left">
            <lazy-menu-product-slots-selected-info
              :selected-slot="slotLeft"
              @click="() => (useLeftSlot = true)"
            />
          </div>
          <div class="v-slots-half-big-selection-slot-right">
            <lazy-menu-product-slots-selected-info
              :selected-slot="slotRight"
              @click="() => (useLeftSlot = true)"
            />
          </div>

          <div
            v-if="baseProduct"
            class="v-w-100 v-d-flex v-flex-row"
            data-test-id="product-half-option"
          >
            <menu-option-selector
              :options="baseProduct.Options"
              :product="baseProduct"
              :threshold="appConfig.VueSettingsPreRun.ProductOptionThreshold"
              data-test-id="product-page-options"
            />
          </div>

          <div class="v-slots-half-big-selection-button-confirm">
            <div
              v-if="isProductUnavailable(slotBase)"
              class="v-flex-100 v-d-flex v-flex-row"
            >
              <div
                v-if="menuStore.ProductsInStopList.includes(slotBase.ID)"
                class="v-nomenclature-card-in-stop-list v-product-in-stop-list v-btn-lg"
                data-test-id="product-in-stop-list"
              >
                <span v-html="translate('productCard.inStopListButton')" />
              </div>
              <common-popover
                v-else
                class="v-w-100"
              >
                <template #hoverable>
                  <div
                    class="v-nomenclature-card-in-stop-list v-product-in-stop-list"
                    data-test-id="slot-half-card-unavailable-by-order-type"
                  >
                    <span v-html="translate('productCard.notAvailableByOrderTypeButton')" />
                  </div>
                </template>
                <template #content>
                  <span
                    v-html="
                      clientStore.orderTypeNotSelected
                        ? translate('productCard.notAvailableByOrderType')
                        : translate('productCard.notAvailableByOrderTypeTooltip', {
                            orderType: translate(clientStore.orderTypeText)
                          })
                    "
                  />
                </template>
              </common-popover>
            </div>
            <arora-button
              v-else
              :disabled="slotsNotReady"
              :label="translate('addToCartButton.addToCart')"
              data-test-id="slots-half-buy"
              @click="async () => await addToBasket()"
            >
              <div class="v-align-currency-center">
                <template v-if="price > 0">
                  <span
                    v-html="
                      translate(
                        slotsNotReady ? 'slotsLunchPage.addToCartFrom' : 'slotsLunchPage.addToCart'
                      )
                    "
                  />
                  <common-currency :amount="price" />
                </template>
                <span
                  v-else
                  v-html="translate('addToCartButton.addToCart')"
                />
              </div>
            </arora-button>
          </div>

          <div class="v-slots-half-big-selection-button-reset">
            <arora-button
              class-name="v-btn v-btn-link v-w-100"
              ignore-settings
              :label="translate('slotsLunchPage.redo')"
              @click="async () => await clearSlots()"
            >
              <div class="v-d-flex v-align-center">
                <icon-general-sync class="v-mr-xs" />
                <span v-html="translate('slotsLunchPage.redo')" />
              </div>
            </arora-button>
          </div>
        </div>
      </div>
    </template>
  </div>
  <common-skeleton v-else />
</template>

<script setup lang="ts">
import { FirstAddType } from '~api/consts'

import type { CartSlot } from '~types/clientStore'
import type { ProductInList, Slot, SlotBase } from '~types/menuStore'
import type { SlotsCommon } from '~types/props'

import { useCommon, useWindowSize } from '@arora/common'

const { slotId, isRecommendation } = defineProps<SlotsCommon>()
const { fromPopup } = useInstance()

const { translate, sanitize } = useI18nSanitized()

const { getRandomInt, stringIsNullOrWhitespace } = useCommon()
const { imageExists } = useImageInfo()
const { isSmall } = useWindowSize()
const appConfig = useAppConfig()

const restaurantStore = useRestaurantStore()
const clientStore = useClientStore()
const menuStore = useMenuStore()
const popupStore = usePopupStore()

const { addToCartWithPayload } = useCartFunctionsWithDialogs()
const { isProductUnavailable } = useProduct()

const slotBase = ref<SlotBase | undefined>()
const slot = ref<Slot | null>(null)
const baseProduct = ref<ProductInList | undefined>()

const slotLeft = ref<ProductInList | null>(null)
const slotRight = ref<ProductInList | null>(null)

const leftSlotProducts = computed<ProductInList[]>(() => slotBase.value?.Slots[0]?.Products ?? [])
const rightSlotProducts = computed<ProductInList[]>(() => slotBase.value?.Slots[1]?.Products ?? [])

const activeIndexLeft = ref<number>(0)
const activeIndexRight = ref<number>(0)

const slotsNotReady = computed<boolean>(() => !slotLeft.value || !slotRight.value)
let slotsForSend: CartSlot[] = []

const price = computed<number>(() => {
  if (!slotBase.value) return 0

  return (
    (slotBase.value.Price > 0 ? slotBase.value.Price : (baseProduct.value?.Price ?? 0)) +
    priceLeft.value +
    priceRight.value
  )
})
const priceLeft = ref<number>(0)
const priceRight = ref<number>(0)
let useLeftSlot = true

async function randomize(): Promise<void> {
  if (!slotBase.value) return
  await clearSlots()

  let randomIndexRight = 0
  let rightItem = rightSlotProducts.value[0]

  const randomIndexLeft = getRandomInt(leftSlotProducts.value.length)
  const leftItem = leftSlotProducts.value[randomIndexLeft]
  activeIndexLeft.value = randomIndexLeft

  do {
    randomIndexRight = getRandomInt(rightSlotProducts.value.length)
    rightItem = rightSlotProducts.value[randomIndexRight]
  } while (rightItem.ID === leftItem.ID) //ensure that slots do not match

  activeIndexRight.value = randomIndexRight

  if (slotLeft.value === null || slotRight.value === null) await randomize()
}

function selectSlotInternal(item: ProductInList): void {
  if (!slotBase.value || !slot.value) return

  if (useLeftSlot) {
    slotLeft.value = item

    if (slotsForSend.length === 0) {
      slotsForSend.push({
        ID: slot.value.ID,
        IsRequired: slot.value.IsRequired,
        Modifiers: {},
        ProductID: item.ID
      })
    } else {
      slotsForSend[0] = {
        ID: slot.value.ID,
        IsRequired: slot.value.IsRequired,
        Modifiers: {},
        ProductID: item.ID
      }
    }

    slot.value = slotBase.value.Slots[1]
    priceLeft.value = item.Price
    useLeftSlot = false
  } else {
    slotRight.value = item
    if (slotsForSend.length <= 1) {
      slotsForSend.push({
        ID: slot.value.ID,
        IsRequired: slot.value.IsRequired,
        Modifiers: {},
        ProductID: item.ID
      })
    } else {
      slotsForSend[1] = {
        ID: slot.value.ID,
        IsRequired: slot.value.IsRequired,
        Modifiers: {},
        ProductID: item.ID
      }
    }
    priceRight.value = item.Price
    useLeftSlot = true
  }
}

function selectSlot(item: ProductInList): void {
  if (!slotBase.value || !slot.value) return
  if (!appConfig.VueSettingsPreRun.MenuSlotsAllowDuplicates) {
    if (slotLeft.value !== null && slotLeft.value.ID === item.ID) {
      slotLeft.value = null
      useLeftSlot = true

      return
    }
    if (slotRight.value !== null && slotRight.value.ID === item.ID) {
      slotRight.value = null
      useLeftSlot = false

      return
    }
  }

  selectSlotInternal(item)
}

async function addToBasket(): Promise<void> {
  if (!slotBase.value || !slot.value) return

  const added = await addToCartWithPayload({
    product: {
      productID: slotId,
      optionID: menuStore.SelectedOptionsPerProduct.get(slotId)?.ID,
      count: 1,
      groupId: slotBase.value.GroupID,
      name: slotBase.value.Name,
      priceModified: price.value
    },
    slots: slotsForSend,
    firstAddType: isRecommendation ? FirstAddType.Recommendation : FirstAddType.Default
  })

  if (added && fromPopup.value) {
    popupStore.closePopup()
  }
}

async function clearSlots(): Promise<void> {
  slotsForSend = []
  slotLeft.value = null
  slotRight.value = null
}

const { makeDefaultOption } = useProduct()

onMounted(async () => {
  baseProduct.value = appConfig.Products.find((product) => product.ID === slotId)
  const optionFromStore = menuStore.SelectedOptionsPerProduct.get(slotId)

  slotBase.value = appConfig.Slots.find(
    (slot) => slot.ID === (optionFromStore ? optionFromStore.ID : slotId)
  )

  if (slotBase.value) {
    slot.value = slotBase.value.Slots[0]
    makeDefaultOption(slotBase.value)
  }
  restaurantStore.Metrics?.SendMetricsProductView(
    slotBase.value!.GroupID,
    slotBase.value!.ID,
    slotBase.value!.Name,
    price.value,
    1,
    menuStore.SelectedOptionsPerProduct.get(slotBase.value!.ID)?.ID
  )
})

watch(
  () => menuStore.SelectedOptionsPerProduct.get(slotId)?.ID,
  async (value, oldValue) => {
    if (value && value !== oldValue && oldValue !== undefined) {
      await clearSlots()
      slotBase.value = appConfig.Slots.find((slot) => slot.ID === value)
      if (slotBase.value) {
        slot.value = slotBase.value.Slots[0]
      }
    }
  }
)

watch(
  () => activeIndexLeft.value,
  (newValue, oldValue) => {
    if (newValue === oldValue) return
    const leftItem = leftSlotProducts.value[newValue]
    useLeftSlot = true

    selectSlotInternal(leftItem)
  }
)
watch(
  () => activeIndexRight.value,
  (newValue, oldValue) => {
    if (newValue === oldValue) return
    const rightItem = rightSlotProducts.value[newValue]
    useLeftSlot = false

    selectSlotInternal(rightItem)
  }
)
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-slots-half-small-title {
  font-weight: 600;
  font-size: 22px;
  width: 100%;

  margin-bottom: 1.1rem;
}
.v-slots-half-mobile-swipers {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: auto;
  position: relative;

  .swiper {
    height: 70vh; //swiper takes enormous height without it
  }

  .swiper-slide {
    display: flex;
    align-items: center;
  }

  .v-swiper-right {
    flex: 0 0 50%;
    max-width: 50%;

    .swiper-pagination-bullet {
      height: 30px;
      border-radius: 8px;
    }

    .swiper-pagination-bullets {
      right: 4px;
      left: auto;
    }

    .swiper-pagination-bullet-active {
      background: variables.$BodyTextColor;
    }
  }

  .v-swiper-left {
    flex: 0 0 50%;
    max-width: 50%;

    .swiper-pagination-bullet {
      height: 30px;
      border-radius: 8px;
    }

    .swiper-pagination-bullets {
      left: 4px;
      right: auto;
    }

    .swiper-pagination-bullet-active {
      background: variables.$BodyTextColor;
    }
  }
}

.v-swiper-left {
  .swiper-pagination-bullets {
    right: unset;
  }
}
.v-item-slot-half {
  display: flex;
  flex-direction: column;
  height: 100%;
  &--top {
    flex-grow: 1;
  }
  &--name {
    font-size: 1.15rem;
    margin-bottom: 4px;
    font-weight: 500;

    display: none;
  }
  &--price {
    font-weight: 600;
    font-size: 1.15rem;
    opacity: 0.4;
    display: none;
    flex-grow: 1;
  }
  &--img {
    height: auto;
    width: 100%;
    overflow: hidden;
    aspect-ratio: 1/1;
    font-weight: 500;
  }
}

.swiper-slide-active {
  .v-item-slot-half {
    &--name,
    &--price {
      display: flex;
    }
  }
}
.v-slots-half-mobile-swiper {
  display: flex;
  flex-direction: column;
  height: 100%;

  &.v--left {
    padding-left: 16px;
    width: 100%;
    .v-item-slot-half--img {
      text-align: right;
    }
    img {
      //transform: translateX(calc(50% * (1/1.05)));//need to move 50% and then adjust to opposite of image scale
      transform: translateX(50%);
      height: 100%;
      width: auto;
      max-width: none;
      aspect-ratio: 1/1;
    }
  }

  &.v--right {
    padding-right: 16px;
    width: 100%;

    img {
      //transform: translateX(calc(-50% * (1/1.05)));//need to move 50% and then adjust to opposite of image scale
      transform: translateX(-50%);
      height: 100%;
      width: auto;
      max-width: none;
      aspect-ratio: 1/1;
    }

    .v-item-slot-half {
      &--name,
      &--price,
      &--top {
        justify-content: end;
        text-align: right;
      }
    }
  }
}

.v-slots-half-big {
  display: flex;
  height: 100%;
  gap: 10px;

  min-height: 525px;
}

.v-cart-half-big-left {
  flex: 0 0 calc(65% - 10px);
  max-width: calc(65% - 10px);

  position: relative;

  &-scroll-area {
    overflow-y: scroll;
    max-height: 600px;
  }
}

.v-slots-half-big-right {
  flex: 0 0 35%;
  max-width: 35%;
  padding-left: 10px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
}

.v-slots-half-big-selection-image {
  position: relative;
  width: 100%;

  .v-btn {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 8px 10px;

    i {
      font-size: 18px;
    }
  }
}

.v-slots-half-big-selection-slot-message {
  display: flex;
  width: 100%;
  color: variables.$BodyTextColorLight;
  font-size: variables.$TextSizeLabel;
  padding: 10px 15px;

  svg {
    width: 16px;
    height: 16px;
    flex: 0 0 16px;
  }
}

.v-slot-half-image {
  display: grid;
  grid-template-areas: 'pic';
}

.v-slots-half-big-selection-slot-left {
  width: 100%;
  padding: 15px;
}

.v-slots-half-big-selection-slot-right {
  width: 100%;
  padding: 15px;
}

.v-slots-half-big-selection-button-confirm {
  width: 100%;
  margin: auto auto 0;
  .v-btn {
    width: 100%;
    font-size: 16px;
  }
}

.v-slots-half-big-selection-button-reset {
  color: variables.$BodyTextColorLight;

  svg {
    path {
      stroke: variables.$BodyTextColorLight;
    }
  }
}

.v-slots-half-big-title {
  font-weight: 600;
  font-size: 22px;
  width: 100%;

  margin-bottom: 1.1rem;
}

.v-cart-half-big-left-scroll-area {
  .v-slot-product-single {
    @include mixins.trans;
    display: flex;
    flex-direction: column;
    padding: 10px;
    cursor: pointer;
    height: 100%;
    border: 1.5px solid transparent;
    border-radius: variables.$BorderRadius;

    &-selected-both {
      border-color: variables.$PrimaryBackgroundColor;

      .v-broken-image {
        grid-area: pic;
      }

      .v-img-fluid {
        grid-area: pic;

        opacity: 0.25;
      }
    }

    &-selected-left {
      border-color: variables.$PrimaryBackgroundColor;

      .v-broken-image {
        grid-area: pic;
      }

      .v-img-fluid {
        grid-area: pic;

        opacity: 0.5;

        &:nth-child(1) {
          opacity: 1;
          clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
        }
      }
    }

    &-selected-right {
      border-color: variables.$PrimaryBackgroundColor;

      .v-broken-image {
        grid-area: pic;
      }

      .v-img-fluid {
        grid-area: pic;

        opacity: 0.5;

        &:nth-child(1) {
          opacity: 1;
          clip-path: polygon(0 0, 50% 0, 50% 100%, 0% 100%);
        }
      }
    }

    &-title {
      font-weight: 500;
      font-size: 1.2rem;
      text-align: center;
    }

    .v-currency-wrapper {
      font-size: variables.$TextSizeMain;
      text-align: center;
      color: variables.$BodyTextColorLight;
    }
  }
}

.v-half-fab-wrapper {
  position: absolute;
  bottom: 25px;
  right: 25px;
  z-index: 9;
}

.v-half-fab {
  color: variables.$PrimaryBackgroundColor;
  z-index: 9999; //to cover swiper index

  background: variables.$BodyElementsBackground;
  border-radius: 50%;
  outline: none;
  box-shadow: variables.$FloatShadow;
  cursor: pointer;
  border: none;
  padding: 7px 9px;

  display: flex;
  align-items: center;
  justify-content: center;

  line-height: 1.2;
  font-size: 32px;
  text-decoration: none;

  &:hover {
    border-color: variables.$PrimaryBackgroundColor;
    background: variables.$PrimaryBackgroundColor;
    color: variables.$PrimaryColor;
  }
}

.v-half-small-cart {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1.1rem;

  .v-btn {
    width: 100%;
  }
}
.v-arora-option-slider {
  flex: 0 0 100%;
  max-width: 100%;
}
</style>
